import React, { useCallback, useEffect, useRef, useState } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import styles from "./b2x-telia-toggle.module.scss";

const rootClassName = "telia-toggle";
const remSize = 10;
const trackHeight = 2 * remSize;
const thumbSize = trackHeight * 1.2;
const thumbMaxDragRange = trackHeight * 1.1;
const thumbPressedLineHeight = Math.round(0.83 * remSize);
const thumbLineWidth = 0.2 * remSize;
const minTimeDiff = 75;

const isTouchEvent = (e: MouseEvent | TouchEvent): e is TouchEvent => e && "touches" in e;

const createClassName = (classNames: (string | boolean | undefined)[]) =>
  classNames
    .filter((className): className is string => !!className)
    .map((className) => styles[className])
    .join(" ");

type Props = {
  ["data-testid"]?: string;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  labelledby?: string;
  labelPosition?: "before" | "after" | "none";
  onChange?: (checked: boolean) => void;
  ["data-track-configuration"]?: string;
};

export const TeliaToggle = ({
  ["data-testid"]: dataTestid,
  checked,
  className,
  disabled = false,
  id,
  label,
  labelledby,
  labelPosition = "before",
  onChange,
  ["data-track-configuration"]: dataTrackConfiguration,
}: Props) => {
  const trackRef = useRef<HTMLDivElement>(null);
  const [dragX, setDragX] = useState(checked ? thumbMaxDragRange : 0);
  const [isMouseDown, setMouseDown] = useState(false);
  const [internalChecked, setInternalChecked] = useState(checked);
  const [dragging, setDragging] = useState(false);
  const [dragEnd, setDragEnd] = useState(0);
  const [lastMouseUpTime, setLastMouseUpTime] = useState(0);
  const [hasOutline, setHasOutline] = useState(false);

  useEffect(() => {
    obsoleteWarning("b2x-telia-toggle", "inputs-toggle--docs");
  }, []);

  useEffect(() => {
    if (checked !== internalChecked) {
      setInternalChecked(checked);
      setDragX(checked ? thumbMaxDragRange : 0);
    }
  }, [checked, internalChecked]);

  const handleChange = () => {
    // prevent setting checked if user was just dragging
    if (Date.now() - dragEnd > minTimeDiff) {
      handleChecked(!internalChecked);
    }
  };

  const handleChecked = useCallback(
    (newChecked: boolean) => {
      setDragX(newChecked ? thumbMaxDragRange : 0);

      if (newChecked !== internalChecked) {
        setInternalChecked(newChecked);
        onChange?.(newChecked);
      }
    },
    [internalChecked, onChange]
  );

  const startDrag = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    setMouseDown(true);
  };

  const isDraggedHalfway = useCallback(() => {
    return internalChecked ? dragX < thumbMaxDragRange / 2 : dragX > thumbMaxDragRange / 2;
  }, [dragX, internalChecked]);

  const onMouseUp = useCallback(() => {
    setMouseDown(false);
    setHasOutline(false);
    setLastMouseUpTime(Date.now());

    if (trackRef.current && dragging) {
      setDragEnd(Date.now());
      setDragging(false);

      if (isDraggedHalfway()) {
        handleChecked(!internalChecked);
      } else {
        setDragX(internalChecked ? thumbMaxDragRange : 0);
      }
    }
  }, [dragging, handleChecked, internalChecked, isDraggedHalfway]);

  const onMouseMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const halfThumbSize = thumbSize / 2;
      const clientX = isTouchEvent(e) ? e.touches[0].clientX : e.clientX;
      const nextDragX = trackRef.current
        ? clientX - trackRef.current.getBoundingClientRect().left - halfThumbSize
        : dragX;

      if (
        isMouseDown &&
        !dragging &&
        (isTouchEvent(e) || Math.abs(dragX - nextDragX) < halfThumbSize)
      ) {
        setDragging(true);
      }

      if (trackRef.current && dragging) {
        if (nextDragX < 0) {
          setDragX(0);
        } else if (nextDragX > thumbMaxDragRange) {
          setDragX(thumbMaxDragRange);
        } else {
          setDragX(nextDragX);
        }
      }
    },
    [dragX, dragging, isMouseDown]
  );

  useEffect(() => {
    // onMouseMove function relies on dragging and isMouseDown state
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchmove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onMouseMove);
    };
  }, [onMouseMove]);

  useEffect(() => {
    // onMouseUp function relies on dragging state when setting value
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("touchend", onMouseUp);

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
    };
  }, [onMouseUp]);

  function getValueByState(onValue: string, offValue: string) {
    if (internalChecked) {
      return isDraggedHalfway() ? offValue : onValue;
    } else {
      return isDraggedHalfway() ? onValue : offValue;
    }
  }

  function calculateThumbPadding() {
    const halfDragRange = thumbMaxDragRange / 2;
    const halfwayDraggedPercentage = Math.abs((dragX - halfDragRange) / halfDragRange);
    const topBottomPadding = `${
      (trackHeight - thumbPressedLineHeight * halfwayDraggedPercentage) / 2
    }px`;
    const onPadding = `${topBottomPadding} ${(trackHeight - thumbLineWidth) / 2}px`;
    const offPadding = `${trackHeight / 2}px`;

    return getValueByState(onPadding, offPadding);
  }

  function getThumbStyle() {
    const cursor = isMouseDown ? "grabbing" : "inherit";
    const padding = calculateThumbPadding();
    const transform = `translateX(${dragX}px)`;
    const transition = `all 200ms ease${dragging ? ", transform 0ms, padding 0ms" : ""}`;

    return { ...((isMouseDown || dragging) && { cursor, padding, transition }), transform };
  }

  function getThumbClass() {
    const thumbBaseClassName = `${rootClassName}__thumb`;

    return createClassName([
      thumbBaseClassName,
      getValueByState(`${thumbBaseClassName}--on`, `${thumbBaseClassName}--off`),
      (dragging || isMouseDown) && `${thumbBaseClassName}--dragging`,
    ]);
  }

  function getTrackClass() {
    const trackBaseClass = `${rootClassName}__track`;

    return createClassName([
      trackBaseClass,
      getValueByState(`${trackBaseClass}--on`, `${trackBaseClass}--off`),
    ]);
  }

  function renderLabel() {
    const labelTextRootClass = `${rootClassName}__label-text`;

    const className = createClassName([
      labelTextRootClass,
      labelPosition && `${labelTextRootClass}--${labelPosition}`,
      disabled && `${labelTextRootClass}--disabled`,
    ]);

    return (
      label &&
      labelPosition !== "none" && (
        <span data-testid="toggle-label-text" className={className}>
          {label}
        </span>
      )
    );
  }

  return (
    <label
      data-testid={dataTestid}
      className={createClassName([
        className,
        rootClassName,
        hasOutline && `${rootClassName}__outlined`,
      ])}
      aria-controls={id}
      onMouseDown={disabled ? undefined : startDrag}
      onTouchStart={disabled ? undefined : startDrag}
      htmlFor={id}
    >
      {labelPosition === "before" && renderLabel()}
      <input
        id={id}
        role="switch"
        type="checkbox"
        checked={internalChecked}
        aria-checked={internalChecked}
        aria-labelledby={labelledby}
        onChange={disabled ? undefined : handleChange}
        disabled={disabled}
        className={styles[`${rootClassName}__input`]}
        onBlur={() => setHasOutline(false)}
        onFocus={() => {
          // prevent focus after user clicked/dragged switch
          if (Date.now() - lastMouseUpTime > minTimeDiff) {
            setHasOutline(true);
          }
        }}
        data-track-configuration={dataTrackConfiguration}
      />
      <div
        role="none"
        data-testid="toggle-track"
        className={getTrackClass()}
        ref={trackRef}
        onClick={() => setHasOutline(false)}
      >
        <div
          role="none"
          data-testid="toggle-thumb"
          className={getThumbClass()}
          style={getThumbStyle()}
          onClick={() => setHasOutline(false)}
        >
          <div className={styles[`${rootClassName}__thumb-line`]} />
        </div>
      </div>
      {labelPosition === "after" && renderLabel()}
    </label>
  );
};
